type Breakpoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

const breakpoints: Breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 1199,
  xl: 1500,
};

export default breakpoints;
