import * as React from "react";

export default function Error() {
  let [count, setCount] = React.useState<number>(5);

  React.useEffect(() => {
    let tmpCount = count;
    setInterval(() => {
      setCount(tmpCount - 1);
    }, 1000);
  });

  React.useEffect(() => {
    if (count === 0) window.location.replace("/");
  }, [count]);

  return (
    <div style={{ textAlign: "center", marginTop: 100 }}>
      <p style={{ fontWeight: "bold" }}>Oups, cette page n'existe pas.</p>
      <p>Vous allez être redirigé dans {count}.</p>
    </div>
  );
}
